/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import styled from "@emotion/styled";
import { useID, Icon, RoundIcon } from "@whitespace/components";
import { visuallyHidden } from "@whitespace/components/dist/utils/styles.module.css";
import cx from "classnames";
import formatDate from "date-fns/format";
import parseDate from "date-fns/parseJSON";
import { useFormikContext, useField, Field } from "formik";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

SearchFormTimeSelectField.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
};

const StyledField = styled(Field)`
  grid-column: 1;
  grid-row: 1;
  z-index: ${(props) => (props.hideLabel ? 3 : 1)};
  &:focus {
    z-index: 3;
  }
  border-color: var(--select-field-control-border-color, #dfe1e4);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  min-height: 38px;
  outline: 0 !important;
  transition: all 100ms;
  box-sizing: border-box;
  color: var(--select-field-control-color, #3d4148);
  font-size: var(--select-field-font-size, 0.875rem);
  font-weight: var(--select-field-font-weight, 400);
  padding: 0 0.25rem;
  @media (max-width: 32rem) {
    width: 280px;
    height: 40px;
  }
`;

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  outline: 0 !important;
  transition: all 100ms;
  box-sizing: border-box;
  color: var(--select-field-control-color, #3d4148);
  font-size: var(--select-field-font-size, 0.875rem);
  font-weight: var(--select-field-font-weight, 400);
  padding: 0 0.5rem;
  pointer-events: none;
  margin: 2px;
  background: white;
`;

export default function SearchFormTimeSelectField({
  id,
  name,
  label,
  // ...restProps
}) {
  const { setFieldValue, values, submitForm } = useFormikContext();
  // const [{ value }] = useField(name);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const generateID = useID();
  const { t } = useTranslation();

  // useEffect(() => {
  //   const start = startDate && formatDate(parseDate(startDate), "yyyy-MM-dd");
  //   const end = endDate && formatDate(parseDate(endDate), "yyyy-MM-dd");
  //   const time = `${start}_${end}`;
  //   if (!start || !end) {
  //     return;
  //   }
  //   setFieldValue(name, time);
  //   setTimeout(submitForm, 0);
  // }, [startDate, endDate]);

  id = id || generateID(name);

  return (
    <>
      <div
        css={css`
          display: grid;
        `}
      >
        <StyledLabel htmlFor={`${id}-from`}>
          {t(["datePicker.placeholder.from", "From date"])}
        </StyledLabel>
        <StyledField
          type="date"
          name={`${name}:gte`}
          id={`${id}-from`}
          // placeholder={t(["datePicker.placeholder.from", "From date"])}
          onChange={(event) => {
            setFieldValue(`${name}:gte`, event.target.value);
            setTimeout(submitForm, 0);
          }}
          hideLabel={values[`${name}:gte`]}
        />
      </div>
      <div
        css={css`
          display: grid;
        `}
      >
        <StyledLabel htmlFor={`${id}-to`}>
          {t(["datePicker.placeholder.to", "To date"])}
        </StyledLabel>
        <StyledField
          type="date"
          name={`${name}:lte`}
          id={`${id}-to`}
          // placeholder={t(["datePicker.placeholder.to", "To date"])}
          onChange={(event) => {
            setFieldValue(`${name}:lte`, event.target.value);
            setTimeout(submitForm, 0);
          }}
          hideLabel={values[`${name}:lte`]}
        />
      </div>
    </>
  );
}
