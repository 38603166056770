import { useStaticQuery, graphql } from "gatsby";

export default function useFalllbackImage() {
  const data = useStaticQuery(graphql`
    query getFallbackImage {
      file(
        sourceInstanceName: { eq: "images" }
        relativePath: { eq: "fallback.png" }
      ) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            sizes
            src
            srcSet
          }
        }
      }
      # site {
      #   siteMetadata {
      #     siteUrl
      #   }
      # }
    }
  `);
  return {
    width: 1200,
    height: 627,
    ...data.file.childImageSharp.fluid,
  };
}
