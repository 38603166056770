/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import { H } from "@jfrk/react-heading-levels";
import { Icon } from "@whitespace/components";
import { TimeInterval } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { decode } from "html-entities";
import PropTypes from "prop-types";

EventSectionDetails.propTypes = {
  location: PropTypes.any,
  occasion: PropTypes.any,
  status: PropTypes.any,
};

export default function EventSectionDetails({
  location,
  occasion,
  status,
  ...restProps
}) {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(min(100%, 16rem), 1fr));
      `}
      {...restProps}
    >
      {
        <div>
          <H className="h5">
            <Icon name="calendar" /> Tidpunkt
          </H>
          {status === "cancelled" && <span>Inställt</span>}
          <TimeInterval
            startDate={occasion.start_date}
            endDate={occasion.end_date}
            format={{
              year: "numeric",
              month: "long",
              day: "numeric",
              // weekday: "short",
              hour: "numeric",
              minute: "numeric",
            }}
          />
        </div>
      }
      {location && (
        <div>
          <H className="h5">
            <Icon name="map-pin" /> Plats
          </H>
          <p>{decode(location.title)}</p>
        </div>
      )}
    </div>
  );
}
