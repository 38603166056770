/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Button, Icon } from "@whitespace/components";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import * as defaultStyles from "./LeaveButton.module.css";

LeaveButton.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function LeaveButton({ styles = defaultStyles }) {
  const { t } = useTranslation();

  function replaceHistoryState() {
    setTimeout(() => {
      window.location.href = "https://google.com";
    }, 0);
  }

  return (
    <Button
      to="/"
      onClick={() => {
        replaceHistoryState();
      }}
      replace
      className={styles.leaveButton}
    >
      <Icon className={styles.icon} name={"icon-close"} />
      {t("leavePageNow")}
    </Button>
  );
}
