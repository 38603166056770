import { Icon, Link } from "@whitespace/components";
import HTML from "@whitespace/gatsby-theme-wordpress-basic/src/components/HTML";
import { useMenu } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/menus";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import LayoutComponent from "../../../components/LayoutComponent";
import FooterLogo from "../../../images/Logo_footer_VIT.svg";

import * as defaultStyles from "./Footer.module.css";

Footer.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function Footer({ styles = defaultStyles, ...restProps }) {
  let menu = useMenu("FOOTER_SHORTCUTS");
  if (!menu || !menu.items || menu.items.length === 0) {
    return null;
  }
  const shortcuts = menu.items;

  let legalMenu = useMenu("FOOTER_LEGAL");
  if (!legalMenu || !legalMenu.items || legalMenu.items.length === 0) {
    return null;
  }
  const legal = legalMenu.items;

  const content = useStaticQuery(graphql`
    query FooterContent {
      wp {
        acfOptionsFooter {
          footerContent {
            about
            address
            email
            telephone
            social {
              icon
              platform
              url
            }
          }
        }
      }
    }
  `).wp.acfOptionsFooter?.footerContent;

  const { t } = useTranslation();
  return (
    <>
      <div className={clsx(styles.navWrapper)}>
        <LayoutComponent
          className={clsx(styles.headerWrapper, styles.wrapper)}
          type={"fullNoMargin"}
        >
          <nav
            className={clsx(styles.shortcuts, "u-hidden-print")}
            aria-label={t("Shortcuts")}
            {...restProps}
          >
            <ul className={clsx(styles.list)}>
              {shortcuts.map((shortcut, index) => {
                return (
                  <li key={index} className={styles.item}>
                    <Link className={styles.link} to={shortcut.url}>
                      {shortcut.extra.icon &&
                        shortcut.extra.icon !== "inherit" && (
                          <Icon
                            className={styles.icon}
                            name={shortcut.extra.icon}
                          />
                        )}
                      {shortcut.label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </LayoutComponent>
      </div>
      <footer {...restProps} className={styles.footer}>
        <LayoutComponent
          className={clsx(styles.headerWrapper, styles.wrapper)}
          type={"fullNoMargin"}
        >
          <div className={styles.main}>
            {!!content && (
              <div className={styles.content}>
                <div className={styles.about}>
                  <HTML>{content.about}</HTML>
                </div>
                <div className={styles.contactInfo}>
                  <div className={styles.contacts}>
                    <div className={styles.bottomSpace}>
                      <HTML>{content.address}</HTML>
                    </div>
                    <div className={styles.bottomSpace}>
                      <HTML>{content.telephone}</HTML>
                    </div>
                  </div>
                  <div>
                    <div className={styles.bottomSpace}>
                      <HTML>{content.email}</HTML>
                    </div>
                    <div>
                      <h4>Följ oss på</h4>
                      <div className={styles.social}>
                        {content.social.map((item) => {
                          return (
                            <Link
                              key={item.platform}
                              to={item.url}
                              className={styles.socialLink}
                            >
                              <Icon
                                name={item.icon}
                                className={styles.socialIcon}
                              />
                              <li key={item.platform}>{item.platform}</li>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className={styles.line} />
            <div className={styles.bottom}>
              <img src={FooterLogo} />
              <nav {...restProps}>
                <ul className={styles.legal}>
                  {legal.map((item, index) => {
                    return (
                      <li key={index} className={styles.legalItem}>
                        <Link to={item.url}>{item.label}</Link>
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          </div>
        </LayoutComponent>
      </footer>
    </>
  );
}
