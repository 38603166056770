/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Button, Icon } from "@whitespace/components";
import HTML from "@whitespace/gatsby-theme-wordpress-basic/src/components/HTML";
import clsx from "clsx";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";

import * as defaultStyles from "./AlertBanner.module.css";
import LayoutComponent from "./LayoutComponent";

AlertBanner.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function AlertBanner({ styles = defaultStyles }) {
  const [dismissedBannerIds, setDismissedBannerIds] = useState([]);

  let data = useStaticQuery(graphql`
    query AlertQuery {
      wp {
        alerts(first: 1000) {
          nodes {
            id
            title
            content
            alertSettings {
              alertSettingsType
            }
          }
        }
      }
    }
  `);
  let alerts = data.wp?.alerts?.nodes;

  if (alerts?.length === 0) {
    return null;
  }

  function dismissAlertBanner(id) {
    setDismissedBannerIds((dismissedBannerIds) => [...dismissedBannerIds, id]);
  }

  function checkForDismissedBanner(id) {
    return dismissedBannerIds?.find((item) => item === id);
  }

  return (
    <>
      {alerts.map(
        ({ id, title, content, alertSettings: { alertSettingsType } }) => {
          return (
            <>
              {!checkForDismissedBanner(id) && (
                <div
                  key={id}
                  className={styles.component}
                  css={{
                    background: `var(--color-${alertSettingsType})`,
                    color: `var(--color-${alertSettingsType}-text)`,
                  }}
                >
                  <LayoutComponent
                    className={clsx(styles.headerWrapper, styles.wrapper)}
                    type={"fullNoMargin"}
                  >
                    <div className={styles.alert}>
                      <div className={styles.header}>
                        <div>
                          <Icon
                            className={styles.icon}
                            name={alertSettingsType}
                          />
                          <span className={styles.title}>{title}</span>
                        </div>
                        <Button
                          onClick={() => {
                            dismissAlertBanner(id);
                          }}
                          replace
                          className={styles.closeButton}
                        >
                          <Icon className={styles.icon} name={"icon-close"} />
                        </Button>
                      </div>
                      <HTML>{content}</HTML>
                    </div>
                  </LayoutComponent>
                </div>
              )}
            </>
          );
        },
      )}
    </>
  );
}
