import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import React from "react";
/** @jsx jsx */
import Helmet from "react-helmet";

ScriptModule.propTypes = {
  module: PropTypes.shape({
    modScriptOptions: PropTypes.shape({
      embedUrl: PropTypes.string,
    }),
  }),
};

export default function ScriptModule({
  module: {
    modScriptOptions: { embedUrl },
  },
}) {
  return (
    <>
      <Helmet>
        <script src={embedUrl} />
      </Helmet>
      <div id="KoladaVisualizerPlaceHolder">&nbsp;</div>
    </>
  );
}
