import { css } from "@emotion/react";
import {
  Card,
  CardContent,
  CardMedia,
  CardMeta,
  CardTitle,
} from "@whitespace/components";
import {
  TermList,
  Time,
} from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import clsx from "clsx";
import { kebabCase } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import * as styles from "./ContentNodeCard.module.css";

WPDefaultContentNodeCard.propTypes = {
  // className: PropTypes.string,
  contentNode: PropTypes.shape({
    content: PropTypes.node,
    date: PropTypes.string,
    excerpt: PropTypes.node,
    image: PropTypes.object,
    theme: PropTypes.string,
    title: PropTypes.node,
    url: PropTypes.string,
    contentType: PropTypes.string,
    taxonomies: PropTypes.arrayOf(PropTypes.object),
  }),
  dateFormat: PropTypes.objectOf(PropTypes.string),
  // styles: PropTypes.objectOf(PropTypes.string),
};

export default function WPDefaultContentNodeCard({
  contentNode,
  dateFormat = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
  ...restProps
}) {
  const { date, excerpt, image, title, url, theme, taxonomies, contentType } =
    contentNode;

  const [isExcerptExpanded, setExcerptExpanded] = useState(false);

  return (
    <Card
      link={url ? { url } : null}
      onClick={!url ? () => setExcerptExpanded(!isExcerptExpanded) : null}
      css={css(`
        cursor: pointer;
        --card-background: ${
          theme ? `var(--brand-color-${kebabCase(theme)})` : null
        };
        --card-hover-background: ${
          theme ? `var(--brand-color-${kebabCase(theme)})` : null
        };
        --card-color: ${
          theme ? `var(--brand-color-${kebabCase(theme)}-text)` : null
        };
        --card-hover-color: ${
          theme ? `var(--brand-color-${kebabCase(theme)}-text)` : null
        };
        --card-meta-color: ${
          theme ? `var(--brand-color-${kebabCase(theme)}-text)` : null
        };
      `)}
      {...restProps}
    >
      <CardContent
        className={clsx(styles[`contentType${contentType ?? "Manual"}`])}
      >
        <CardTitle>{title}</CardTitle>
        {date && (
          <CardMeta>
            <Time
              capitalize={true}
              className={clsx(styles.date)}
              date={date}
              format={{
                weekday: "long",
                month: "long",
                day: "numeric",
              }}
            />
          </CardMeta>
        )}
        {excerpt && (
          <p
            className={clsx(
              styles.excerpt,
              !isExcerptExpanded && styles.collapsed,
            )}
          >
            {excerpt}
          </p>
        )}
        {taxonomies && taxonomies.length > 0 && (
          <TermList taxonomies={taxonomies} />
        )}
      </CardContent>
      {image && <CardMedia image={image} aspectRatio={2 / 1} />}
    </Card>
  );
}
