import { useLocation } from "@gatsbyjs/reach-router";
import CookieConsent from "@whitespace/gatsby-plugin-cookie-consent/src/components";
import useCookieConsentSettings from "@whitespace/gatsby-plugin-cookie-consent/src/hooks/useCookieConsentSettings";
import Footer from "@whitespace/gatsby-theme-wordpress-basic/src/components/Footer";
import PageBreadcrumbs from "@whitespace/gatsby-theme-wordpress-basic/src/components/PageBreadcrumbs";
import { useIsFullWidthPage } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/page-context";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import AlertBanner from "../../../components/AlertBanner";
import DisturbanceBanner from "../../../components/DisturbanceBanner";
import LayoutComponent from "../../../components/LayoutComponent";
import LeaveButton from "../../../components/LeaveButton";

import Header from "./Header";
import * as defaultStyles from "./SiteLayout.module.css";
SiteLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  styles: PropTypes.objectOf(PropTypes.string),
};

export default function SiteLayout({
  children,
  className,
  styles = defaultStyles,
}) {
  let isFullWidthPage = useIsFullWidthPage();
  const location = useLocation();
  const { position, active, strings } = useCookieConsentSettings();
  const pageContext = usePageContext();
  const showLeaveButton = pageContext?.navigationSettings?.showLeaveButton;
  return (
    <div className={clsx(styles.component, className)}>
      <AlertBanner />
      {showLeaveButton && <LeaveButton />}
      <Header />
      <main className={styles.main} id="main">
        {!isFullWidthPage &&
          !location?.pathname?.startsWith(
            "/offline-plugin-app-shell-fallback",
          ) && (
            <div className={styles.breadcrumbs}>
              <LayoutComponent type={"fullNoMargin"}>
                <PageBreadcrumbs />
              </LayoutComponent>
            </div>
          )}

        {children}
      </main>
      <Footer />
      <DisturbanceBanner />
      <CookieConsent
        position={position}
        cookieConsentSettings={strings}
        active={active}
      />
    </div>
  );
}
