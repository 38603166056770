/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import ModularityArea from "@municipio/gatsby-theme-basic/src/components/ModularityArea";
import { PageContent } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import * as defaultStyles from "@whitespace/gatsby-theme-wordpress-basic/src/components/ArticleBody.module.css";
import TextContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/TextContent";
import WPBlocks from "@whitespace/gatsby-theme-wordpress-basic/src/components/WPBlocks";
import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Fragment } from "react";

import EventPageContent from "../../../components/EventPageContent";

ArticleBody.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  className: PropTypes.string,
};

export default function ArticleBody({ styles = defaultStyles, ...restProps }) {
  let pageContext = usePageContext();
  let blocksJSON = pageContext.contentNode?.blocksJSON;
  let contentArea = pageContext.contentNode?.contentArea;
  let contentHTML = pageContext.contentNode?.content;
  let contentMedia = pageContext.contentNode?.contentMedia;
  // let contentType = pageContext.contentNode?.contentType?.node.name;
  let postMetaJSON = pageContext.contentNode?.postMetaJSON;

  return (
    <Fragment>
      {!!postMetaJSON && (
        <EventPageContent
          css={css`
            margin-bottom: 2rem;
          `}
          postMeta={JSON.parse(postMetaJSON)}
        />
      )}
      <TextContent {...restProps}>
        {blocksJSON ? (
          <Fragment>
            <WPBlocks
              blocks={JSON.parse(blocksJSON)}
              contentMedia={contentMedia}
            />
          </Fragment>
        ) : (
          <PageContent input={contentHTML} contentMedia={contentMedia}>
            {({ preamble, content }) => (
              <Fragment>
                {preamble && (
                  <div className={clsx(styles.preamble)}>{preamble}</div>
                )}
                {content}
              </Fragment>
            )}
          </PageContent>
        )}
      </TextContent>
      <ModularityArea area={contentArea} />
    </Fragment>
  );
}
