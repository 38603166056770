import { CalendarBadge } from "@whitespace/components";
import PropTypes from "prop-types";
import React from "react";

CardEventBadge.propTypes = {
  date: PropTypes.any,
};

export default function CardEventBadge({ date }) {
  date = new Date(date);
  return <CalendarBadge locale="sv-SE" date={date} />;
}
