import { usePageContext } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks";
import clsx from "clsx";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";

import { useSearch } from "../hooks";

import * as styles from "./SearchForm.module.css";
import SearchFormField from "./SearchFormField";

SearchForm.propTypes = {
  className: PropTypes.string,
};

export default function SearchForm({ className, ...props }) {
  const { params, setParams, schema } = useSearch();
  const { t } = useTranslation();
  const location = window.location.pathname;
  let pageContext = usePageContext();
  const isArchivePage = pageContext?.isArchivePage;

  return (
    <Formik
      initialValues={params}
      enableReinitialize={true}
      validationSchema={schema}
      onSubmit={async (values) => {
        setParams({ ...values, page: null });
      }}
      {...props}
    >
      {({ setFieldValue, submitForm }) => (
        <Form className={clsx(styles.form, className)}>
          <SearchFormField param="query" />
          <SearchFormField param="contentType" />

          <div
            className={clsx(
              styles.filterContainer,
              isArchivePage && styles.archivePage,
            )}
          >
            <SearchFormField param="tags" />
            <SearchFormField param="day" />
            {location.includes("evenemang") && (
              <>
                <SearchFormField param="category" />
                <SearchFormField param="location" />
              </>
            )}
            {!!(
              params.tags?.length ||
              params.date?.length ||
              params.category?.length ||
              params.location?.length
            ) && (
              <button
                className={styles.clearFilter}
                onClick={() => {
                  setFieldValue("tags", null);
                  setFieldValue("date", null);
                  setFieldValue("category", null);
                  setFieldValue("location", null);
                  setTimeout(submitForm, 0);
                }}
              >
                {t("clearFilterLabel")}
              </button>
            )}
          </div>
          <div className={styles.sortContainer}>
            <div className={styles.toggleButtonGroupWrapper}>
              <SearchFormField param="sort" />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
