import useSearchDocuments from "@whitespace/gatsby-plugin-search/src/backend/minisearch/useSearchDocuments";
import { useFormikContext, useField } from "formik";
import { flattenDeep, isEmpty, negate } from "lodash-es";
import PropTypes from "prop-types";
import React from "react";

import SelectField from "../SelectField";

SearchFormLocationSelectField.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
};

export default function SearchFormLocationSelectField({ name, ...restProps }) {
  const { setFieldValue, submitForm } = useFormikContext();
  const [{ value }] = useField(name);
  const isNotEmpty = negate(isEmpty);

  function normalizeDocuments(documents) {
    return flattenDeep(documents)
      ?.filter(isNotEmpty)
      ?.map((document) => ({
        ...document,
        text:
          (Array.isArray(document.text)
            ? flattenDeep(document.text)?.filter(isNotEmpty)?.join("\n")
            : document.text) || "",
      }));
  }
  let documents = normalizeDocuments(useSearchDocuments());

  let locations = new Set();

  documents.forEach((post) => {
    if (!post.location) {
      return;
    }
    locations.add(post?.location);
  });

  locations = [...locations];

  return (
    <SelectField
      name={name}
      isMulti={false}
      placeholder="Plats"
      value={value}
      onChange={(value) => {
        setFieldValue(name, value);
        setTimeout(submitForm, 0);
      }}
      options={locations}
      {...restProps}
    />
  );
}
