import { useFormikContext, useField } from "formik";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import SelectField from "../SelectField";

SearchFormCategorySelectField.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
};

export default function SearchFormCategorySelectField({ name, ...restProps }) {
  const { setFieldValue, submitForm } = useFormikContext();
  const [{ value }] = useField(name);

  const {
    allEvents: { nodes: posts },
  } = useStaticQuery(graphql`
    query EventArchiveQuery {
      wp {
        allEvents(first: 100000) {
          nodes {
            categories
          }
        }
      }
    }
  `).wp;

  let categories = new Set();

  posts.forEach((post) => {
    if (!post.categories) {
      return;
    }
    post.categories.forEach((category) => {
      categories.add(category);
    });
  });

  categories = [...categories];

  return (
    <SelectField
      name={name}
      isMulti={false}
      placeholder="Kategori"
      value={value}
      onChange={(value) => {
        setFieldValue(name, value);
        setTimeout(submitForm, 0);
      }}
      options={categories}
      {...restProps}
    />
  );
}
