// extracted by mini-css-extract-plugin
export var arrow = "GalleryModule-module--arrow--J6yHJ";
export var arrowDisabled = "GalleryModule-module--arrowDisabled--3vuxR";
export var arrowIcon = "GalleryModule-module--arrowIcon--UwGbE";
export var arrowNext = "GalleryModule-module--arrowNext--T5saW";
export var arrowPrev = "GalleryModule-module--arrowPrev--PBDQo";
export var autoPlayControlIcon = "GalleryModule-module--autoPlayControlIcon--Z0Bna";
export var autoplayControl = "GalleryModule-module--autoplayControl--CUzKW";
export var autoplayControlWithCaption = "GalleryModule-module--autoplayControlWithCaption--OMl-l";
export var component = "GalleryModule-module--component--v0Y0u";
export var dots = "GalleryModule-module--dots--gYEFH";
export var imageCaption = "GalleryModule-module--imageCaption--0PNkc";
export var imagePhotographer = "GalleryModule-module--imagePhotographer--iOezR";
export var inner = "GalleryModule-module--inner--gmKeB";