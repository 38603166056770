/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { Button } from "@whitespace/components";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
export default function DisturbanceBanner() {
  const { t } = useTranslation();
  let data = useStaticQuery(graphql`
    query MyQuery {
      wp {
        disturbances(first: 1000) {
          nodes {
            id
          }
        }
      }
    }
  `);
  let count = data.wp?.disturbances?.nodes?.length || 0;

  if (!count) {
    return null;
  }

  return (
    <Button
      to={"/driftinformation"}
      css={css`
        position: fixed;
        bottom: 0;
        right: 1rem;
        --button-background: var(--color-green);
        --button-border-radius: 0;
        --button-color: var(--color-black);
        --button-current-color: var(--color-black);
        display: grid;
        grid-template-columns: max-content 1fr;
        gap: 0.375rem;
        font-weight: 500;
        cursor: pointer;
        --button-hover-background: var(--color-green);
        --button-current-background: var(--color-green);
        --button-padding: 0.75rem;
      `}
    >
      <span
        css={css`
          background-color: var(--color-black);
          color: var(--color-white);
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 100%;
          display: flex;
          justify-content: center;
        `}
      >
        {count}
      </span>
      <span>{t("ongoingDisturbances", { count })}</span>
    </Button>
  );
}
