import * as postsModuleComponents from "@municipio/gatsby-theme-basic/src/components/modularity-modules/posts-modules";
import PostsModuleFilterProvider from "@municipio/gatsby-theme-basic/src/components/modularity-modules/PostsModuleFilterProvider";
import normalizePostsModuleItems from "@municipio/gatsby-theme-basic/src/utils/normalizePostsModuleItems";
import { HTML } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import { useHTMLProcessor } from "@whitespace/gatsby-theme-wordpress-basic/src/hooks/html-processor";
import { camelCase, upperFirst } from "lodash/fp";
import PropTypes from "prop-types";
import React from "react";

import useFallbackImage from "../../../../hooks/useFallbackImage";

function visibleFields(item) {
  return {
    showDate: item?.includes("date"),
    showImage: item?.includes("image"),
    showExcerpt: item?.includes("excerpt"),
  };
}

const normalizeHit =
  ({
    modPostsDataDisplay,
    // HTML,
    // stripHTML,
    fallbackImage,
  }) =>
  (item) => {
    const { showDate, showImage, showExcerpt } = visibleFields(
      modPostsDataDisplay.postsFields,
    );
    // let processedContent = processContent(item.content);
    return {
      ...item,
      title: item.label,
      excerpt: showExcerpt && item.text,
      content: item.text,
      image: showImage && (item.image || fallbackImage),
      date: showDate && item.date,
      location: item.postMeta?.location?.title,
      // taxonomies: useTaxonomies(
      //   { ...item.tags?.nodes, ...item.categories?.nodes },
      //   item.contentType?.node?.name,
      // ),
    };
  };

PostsModule.propTypes = {
  module: PropTypes.shape({
    modPostsDataSource: PropTypes.shape({
      postsDataSource: PropTypes.string,
    }),
    modPostsDataDisplay: PropTypes.shape({
      postsDisplayAs: PropTypes.string,
    }),
    modPostsDataFiltering: PropTypes.shape({
      frontEndTaxFiltering: PropTypes.bool,
    }),
  }),
};

function fromDisplayModeToComponentName(displayMode) {
  return displayMode && upperFirst(camelCase(displayMode)) + "PostsModule";
}

export default function PostsModule({ module, ...restProps }) {
  let displayMode = module?.modPostsDataDisplay?.postsDisplayAs;
  let isFilteringEnabled =
    !!module?.modPostsDataFiltering?.frontEndTaxFiltering &&
    module?.modPostsDataSource?.postsDataSource === "posttype";

  const { stripHTML } = useHTMLProcessor();
  const fallbackImage = useFallbackImage();
  let componentName = fromDisplayModeToComponentName(displayMode);
  let Component =
    // eslint-disable-next-line import/namespace
    (componentName && postsModuleComponents[componentName]) ||
    // eslint-disable-next-line import/namespace
    postsModuleComponents.DefaultPostsModule;

  if (isFilteringEnabled) {
    return (
      <PostsModuleFilterProvider>
        {({ hits }) => (
          <Component
            module={module}
            normalizedItems={(hits || []).map(
              normalizeHit({ ...module, HTML, stripHTML, fallbackImage }),
            )}
            {...restProps}
          />
        )}
      </PostsModuleFilterProvider>
    );
  }

  return (
    <Component
      module={module}
      normalizedItems={normalizePostsModuleItems(module, {
        HTML,
        stripHTML,
        fallbackImage,
      })}
      {...restProps}
    />
  );
}
