/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { H, Section } from "@jfrk/react-heading-levels";
import { Accordion } from "@whitespace/components";
import { visuallyHidden } from "@whitespace/components/dist/utils/styles.module.css";
import TextContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/TextContent";
import PropTypes from "prop-types";

import EventBooking from "./EventSectionBooking";
import EventDates from "./EventSectionDates";
import EventDetails from "./EventSectionDetails";
import EventInformation from "./EventSectionInformation";
import EventLinks from "./EventSectionLinks";
import EventPlace from "./EventSectionPlace";

EventPageContent.propTypes = {
  postMeta: PropTypes.object,
};

export default function EventPageContent({ postMeta, ...restProps }) {
  const {
    accessibility,
    contact_email,
    contact_information,
    location: defaultLocation,
    occasions_complete: occasions,
    organizers,
    status,
  } = postMeta;

  // Booking and tickets
  const {
    additional_ticket_retailers,
    additional_ticket_types,
    age_restriction,
    booking_email,
    booking_group,
    booking_link,
    booking_phone,
    children_age,
    membership_cards,
    price_adult,
    price_children,
    price_information,
    price_range,
    price_senior,
    price_student,
    senior_age,
    ticket_includes,
    ticket_release_date,
    ticket_stock,
    tickets_remaining,
  } = postMeta;

  // Links
  const {
    apple_music,
    deezer,
    facebook,
    google_music,
    instagram,
    soundcloud,
    spotify,
    twitter,
    vimeo,
    youtube,
  } = postMeta;

  const nextOccasion =
    occasions.find(
      (occasion) =>
        new Date(occasion.end_date || occasion.start_date) > new Date(),
    ) || occasions[occasions.length - 1];

  const location =
    defaultLocation || nextOccasion.location
      ? {
          ...defaultLocation,
          ...(nextOccasion && nextOccasion.location),
        }
      : false;

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 100%;
        gap: 1rem;
      `}
      {...restProps}
    >
      <H className={visuallyHidden}>Information om evenemanget</H>
      <Section>
        <EventDetails
          occasion={nextOccasion}
          location={location}
          status={status}
        />
        <Accordion
          items={[
            (organizers || accessibility) && {
              title: "Information",
              content: (
                <EventInformation
                  organizers={organizers}
                  accessibility={accessibility}
                  // ageGroup={age_group}
                />
              ),
            },
            location && {
              title: "Plats",
              content: <EventPlace location={location} />,
            },
            occasions &&
              occasions.length > 1 && {
                title: "Datum",
                content: <EventDates occasions={occasions} />,
              },
            (contact_email || contact_information) && {
              title: "Kontakt",
              content: (
                <TextContent>
                  <address className="event__contact">
                    {contact_information}
                    <br />
                    {contact_email}
                  </address>
                </TextContent>
              ),
            },
            ticket_release_date && {
              title: "Biljetter",
              content: (
                <EventBooking
                  {...{
                    additional_ticket_retailers,
                    additional_ticket_types,
                    age_restriction,
                    booking_email,
                    booking_group,
                    booking_link,
                    booking_phone,
                    children_age,
                    membership_cards,
                    price_adult,
                    price_children,
                    price_information,
                    price_range,
                    price_senior,
                    price_student,
                    senior_age,
                    ticket_includes,
                    ticket_release_date,
                    ticket_stock,
                    tickets_remaining,
                  }}
                />
              ),
            },
            (apple_music ||
              deezer ||
              facebook ||
              google_music ||
              instagram ||
              soundcloud ||
              spotify ||
              twitter ||
              vimeo ||
              youtube) && {
              title: "Länkar",
              content: (
                <EventLinks
                  {...{
                    apple_music,
                    deezer,
                    facebook,
                    google_music,
                    instagram,
                    soundcloud,
                    spotify,
                    twitter,
                    vimeo,
                    youtube,
                  }}
                />
              ),
            },
          ].filter(Boolean)}
        />
      </Section>
    </div>
  );
}
