import { H } from "@jfrk/react-heading-levels";
import { Icon, Link } from "@whitespace/components";
import React from "react";

import Button from "../Button/Button";

import * as defaultStyles from "./BoxNavigationWithImage.module.css";

export default function BoxNavigationWithImage({
  image,
  title,
  preamble,
  link,
  items,
  styles = defaultStyles,
  ...restProps
}) {
  return (
    <div {...restProps} className={styles.rowWrapper}>
      {image && (
        <img
          base64=""
          src={image.src}
          srcSet={image.srcSet}
          aspectRatio={16 / 9}
          width={image.width}
          height={image.height}
          alt={image.altText}
          className={styles.image}
        />
      )}
      <div className={styles.navigationBox}>
        {title || preamble ? (
          <div className={styles.navigationHeader}>
            {title ? (
              link ? (
                <>
                  <Button
                    title={title}
                    link={link}
                    buttonModifier="prominent-link"
                    iconModifier="primary"
                  />
                  <Icon
                    className={styles.icon}
                    name={"arrow-right-with-tail"}
                  />
                </>
              ) : (
                <H>{title}</H>
              )
            ) : null}
            {preamble ? <p>{preamble}</p> : null}
          </div>
        ) : null}
        {items && items.length ? (
          <ul className={styles.navigationList}>
            {items.map((item, index) => {
              return (
                <li key={index} className={styles.navigationListItem}>
                  <Link to={item.uri}>{item.title}</Link>
                </li>
              );
            })}
          </ul>
        ) : null}
        <div className={styles.line} />
      </div>
    </div>
  );
}
