/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import {
  Icon,
  Teaser,
  TeaserContent,
  TeaserMedia,
  TeaserMeta,
  TeaserTitle,
} from "@whitespace/components";
import {
  TermList,
  Time,
} from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import * as styles from "@whitespace/gatsby-theme-wordpress-basic/src/components/content-node-teasers/ContentNodeTeaser.module.css";
import clsx from "clsx";
import PropTypes from "prop-types";

import CardEventBadge from "../CardEventBadge";

WPEventTeaser.propTypes = {
  className: PropTypes.string,
  contentNode: PropTypes.shape({
    content: PropTypes.node,
    date: PropTypes.string,
    excerpt: PropTypes.node,
    image: PropTypes.object,
    location: PropTypes.node,
    theme: PropTypes.string,
    title: PropTypes.node,
    url: PropTypes.string,
    taxonomies: PropTypes.arrayOf(PropTypes.object),
  }),
  dateFormat: PropTypes.objectOf(PropTypes.string),
  // styles: PropTypes.objectOf(PropTypes.string),
};

export default function WPEventTeaser({
  className,
  contentNode,
  dateFormat = {
    weekday: "long",
    month: "long",
    day: "numeric",
  },
  ...restProps
}) {
  const { date, excerpt, image, title, url, taxonomies, location } =
    contentNode;
  return (
    <Teaser
      className={clsx(className, styles.teaser)}
      link={{ url }}
      {...restProps}
    >
      <TeaserContent
        className={clsx(styles.content)}
        css={css`
          --calendar-badge-width: 2.5rem;
          --calendar-badge-height: 2.5rem;
          --calendar-badge-day-font-size: 1rem;
          display: grid;
          grid-template: "badge content" auto/ max-content 1fr;
          gap: 0.5rem;
        `}
      >
        <CardEventBadge
          date={date}
          css={css`
            grid-area: badge;
          `}
        />
        <div
          css={css`
            grid-area: content;
          `}
        >
          <TeaserTitle>{title}</TeaserTitle>
          {date && (
            <TeaserMeta>
              <ul
                css={css`
                  list-style: none;
                  padding: 0;
                  margin: 0;
                  display: grid;
                  grid-template-columns: 100%;
                  gap: 0.25rem;
                  line-height: 1.25em;
                  margin-top: 0.5rem;
                `}
              >
                <li>
                  <Icon name="calendar" size={16} />{" "}
                  <Time
                    capitalize={true}
                    className={clsx(styles.date)}
                    date={date}
                    format={dateFormat}
                  />
                </li>
                {!!location && (
                  <li>
                    <Icon name="map-pin" size={16} /> {location}
                  </li>
                )}
              </ul>
            </TeaserMeta>
          )}
        </div>
        {excerpt && <p className={clsx(styles.excerpt)}>{excerpt}</p>}
        {taxonomies && taxonomies.length > 0 && (
          <TermList taxonomies={taxonomies} />
        )}
      </TeaserContent>
      {image && <TeaserMedia image={image} />}
    </Teaser>
  );
}
