import PropTypes from "prop-types";
import React from "react";

import SEO from "../../../components/seo";
import DefaultPageTemplate from "../components/Article";

SingleTemplate.propTypes = {
  pageContext: PropTypes.object,
};

export default function SingleTemplate({ pageContext }) {
  const {
    contentNode: { title, isFrontPage },
  } = pageContext;

  return (
    <>
      <SEO title={title} isFrontPage={isFrontPage} />
      <DefaultPageTemplate />
    </>
  );
}
