import parseDate from "date-fns/parse";
import { camelCase, transform } from "lodash-es";
import { useTranslation } from "react-i18next";

import formatMonth from "../utils/formatMonth";
import fromFacetsToOptions from "../utils/fromFacetsToOptions";
import getOptionsFromTaxonomy from "../utils/getOptionsFromTaxonomy";

export default function useArchiveParamTypes() {
  const { t, i18n } = useTranslation();
  return {
    contentType: {
      type: "string",
      multi: false,
      control: "buttonGroup",
      options: ({ facets, features }) =>
        facets?.contentType &&
        fromFacetsToOptions(facets?.contentType, {
          showCounts: features.includes("facetCounts"),
          sortBy: "value",
          label: (value) =>
            t(`search.facetLabels.contentType.${camelCase(value)}`),
          anyLabel: () => t(`search.facetLabels.contentType.any`),
        }),
    },
    tags: {
      type: "string",
      multi: true,
      control: "select",
      options: getOptionsFromTaxonomy("tag"),
      conditions: { contentType: (value) => value === "post" },
    },
    day: {
      type: "string",
      control: "date-range",
      multi: false,
    },
    category: {
      type: "string",
      control: "category-select",
      multi: false,
    },
    location: {
      type: "string",
      control: "location-select",
      multi: false,
    },
    sort: {
      type: "string",
      multi: false,
      control: "links",
      options: () => ({
        "": t("relevance"),
        "publishDate:desc": t(`publishDate`),
      }),
    },
  };
}
