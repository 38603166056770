// extracted by mini-css-extract-plugin
export var about = "Footer-module--about--LWawX";
export var bottom = "Footer-module--bottom--ZNnWD";
export var bottomSpace = "Footer-module--bottomSpace--HsV9O";
export var contactInfo = "Footer-module--contactInfo--i1lqp";
export var contacts = "Footer-module--contacts--rJjel";
export var content = "Footer-module--content--r-JXm";
export var footer = "Footer-module--footer--wMb+0";
export var icon = "Footer-module--icon--3Wngr";
export var item = "Footer-module--item--yct+4";
export var legal = "Footer-module--legal--Omu7B";
export var legalItem = "Footer-module--legalItem--oBaPr";
export var line = "Footer-module--line---l+yT";
export var link = "Footer-module--link--g-igJ";
export var list = "Footer-module--list--1tXGt";
export var main = "Footer-module--main--RldQL";
export var navWrapper = "Footer-module--navWrapper--PjYjg";
export var shortcuts = "Footer-module--shortcuts--c-SWB";
export var social = "Footer-module--social--lcYdc";
export var socialIcon = "Footer-module--socialIcon--YnOWV";
export var socialLink = "Footer-module--socialLink--tbkkp";