import { Link } from "@whitespace/components";
import TextContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/TextContent";
import { decode } from "html-entities";
import PropTypes from "prop-types";
import React from "react";

EventSectionPlace.propTypes = {
  location: PropTypes.object,
};

export default function EventSectionPlace({ location }) {
  return (
    <TextContent>
      <Link
        to={`https://www.google.com/maps/search/?api=1&query=${location.street_address}+${location.postal_code}+${location.city}&zoom=18`}
      >
        {decode(location.title)}
      </Link>
      <address>
        {location.street_address && <p>{location.street_address}</p>}
        {location.postal_code && <p>{location.postal_code}</p>}
        {location.city && <p>{location.city}</p>}
      </address>
    </TextContent>
  );
}
