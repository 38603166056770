import SearchHits from "@whitespace/gatsby-plugin-search/src/components/SearchHits";
import useSearch from "@whitespace/gatsby-plugin-search/src/hooks/useSearch";
import React from "react";
import { useTranslation } from "react-i18next";

export default function SearchResults({ ...restProps }) {
  const { t } = useTranslation();
  const { hits, isReady, isPending, error, isEmptySearch } = useSearch();
  const fetchingSearchResults = t("fetchingSearchResults");
  const errorSearchResults = t("errorSearchResults");
  const noSearchResults = t("noSearchResults");

  return (
    <div {...restProps}>
      {isEmptySearch ? null : isPending || !isReady ? (
        <div>{fetchingSearchResults}</div>
      ) : error ? (
        <div>{errorSearchResults}</div>
      ) : !hits || hits.length === 0 ? (
        <div>{noSearchResults}</div>
      ) : (
        <SearchHits hits={hits} />
      )}
    </div>
  );
}
