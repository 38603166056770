import { H } from "@jfrk/react-heading-levels";
import { Icon } from "@whitespace/components";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";

import Button from "../Button/Button";

import * as defaultStyles from "./LandingPageHero.module.css";

LandingPageHero.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  title: PropTypes.string,
  shortcuts: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  serviceLink: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
};

export default function LandingPageHero({
  title,
  shortcuts,
  serviceLink,
  styles = defaultStyles,
  ...restProps
}) {
  return (
    <div className={clsx(styles.hero)} {...restProps}>
      <div className="c-hero-butler__wrapper">
        <H className="c-hero-butler__title">{title}</H>
        <div className="c-hero-butler__items" {...restProps}>
          <ul aria-describedby="shortcuts" className={styles.list}>
            {serviceLink && serviceLink.url ? (
              <li className={styles.heroButton}>
                <Icon className={styles.icon} name={"network-phone"} />
                <Button
                  title={serviceLink.title}
                  link={serviceLink.url}
                  buttonModifier="secondary"
                />
              </li>
            ) : null}
            {shortcuts
              ? shortcuts.map(({ title, url }, index) => {
                  return (
                    <li key={index} className="">
                      <Button
                        title={title}
                        className="c-button--theme"
                        link={url}
                      />
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
      </div>
    </div>
  );
}
