import { TimeInterval } from "@whitespace/gatsby-theme-wordpress-basic/src/components";
import TextContent from "@whitespace/gatsby-theme-wordpress-basic/src/components/TextContent";
import PropTypes from "prop-types";
import React from "react";

EventSectionDates.propTypes = {
  occasions: PropTypes.arrayOf(PropTypes.object),
};

export default function EventSectionDates({ occasions }) {
  return (
    <TextContent>
      <ul>
        {occasions.map((occasion, index) => {
          return (
            <li key={index}>
              <TimeInterval
                startDate={occasion.start_date}
                endDate={occasion.end_date}
                format={{
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  // weekday: "short",
                  hour: "numeric",
                  minute: "numeric",
                }}
              />
              {occasion.location ? `, ${occasion.location.title}` : null}
            </li>
          );
        })}
      </ul>
    </TextContent>
  );
}
